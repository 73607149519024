
@use 'font.scss';
@use 'layout.scss';
@use 'animation.scss';
@use 'jsfonction.scss';
@use 'mobile.scss';

html {
  box-sizing: border-box;
  font-size: 16px;
  background-color: #121212;

}
body{
  overflow-x: hidden;
}
.head{
  height: 150px;
  &__lien{
    display: block;
    position: fixed;
    width: 142px;
    height: 25px;
    text-align: center;
    z-index: 99999;
    padding: 15px 20px ;
    font-weight: 900;
    border: 2px solid #fff;
    color: #fff;
    text-decoration: none;
    top: 33px;
    right: 20px;
  }
  &__lien:hover{
    background-color: #fff;
    transition: 0.6s;
    color: #121212;
  }
  
}
.sect{
  height: 270vh;
  &--credit{
    height: 110vh;
  }
  &--body{
    height: 380vh;

  }
  &--position{
    position: relative;
    top: -800px;
    height: 300vh;
  }
  &--ecart{
    margin: 400px 50px;
  }
  &--titrePlacement{
    position: relative;
    top: 70;
    /*left: 212;*/ 
    left: 2vw;
    height: 60vh;
    width: 80vw;
  }
  &__box{
   /* &--2{
      margin: 120px 0 0 0;
      width: 680px;
      height: 680px;
    }*/
    &--1{
      display: flex;
      flex-direction: column;
      list-style: square;
    }
    &--3{
      display: flex;
      flex-direction: row;
      position: sticky;
     /* right: -200 ;
      left: 60vw;*/
      overflow: hidden;
      margin: 0 0 0 2vw;
      top: 60px;
      bottom: 60vh;
      z-index: -2;
    }
  }
  &__cercle{
    &--p{
      width: 100px;
      height: 100px;
    }
    &--m{
      width: 250px;
      height: 250px;
    }
    &--g{
      width: 350px;
      height: 350px;
    }
    &--ecart{
      margin: 0 0 80px 0;
    }
  }
  &__lien{
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    font-family: 'Roboto Mono', monospace;
    text-decoration: none;
    &--icon{
      fill: red;
    }
   
  }
  &__lien:hover{
    transition: 0.3s;
    color:#FF0000
  }
  &--défilement{
    width: 100vw;
    overflow: hidden;
    margin: 0 0 81px 0;
  }
  
}
.foot{
  margin: 280px 0 0 0;
  &--flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__element{
   
  }
  &__list{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    list-style: none;
  }
  &__lien{
    &--start{
      text-align: start;
    }
  }
}
.box{
  &__cercle{
    opacity: 0.05;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    height: 700px;
    &--first{
      margin: 200px -40 0 0;
    }
  }
}
@media(min-width:600px){
  .head{
    &__lien{
      top: 33;
      right: 66;
      left: auto;
      padding: 22px 49px ;
      }
    }
  .sect{
      &--titrePlacement{
        left: 5vw;
        height: 80vh;
      }
      &__box{
        &--3{
          overflow: visible;
          margin: 0 0 5vw 0;
          top: 100;
        }
      }
  }

}
@media(min-width:1024px){
  .head{
    &__lien{
      top: 40;
      right: 80;
      left: auto;
      padding: 22px 49px ;
      }
    }
    .sect{
      &--titrePlacement{
        left: 5vw;
        right: 20;
        height: 80vh;
      }
      &__box{
        &--3{
          overflow: visible;
          margin: 0 0 0 60vw;
          top: 20;
        }
      }
      &__cercle{
        &--p{
          width: 270px;
          height: 270px;
        }
        &--m{
          width: 350px;
          height: 350px;
        }
        &--g{
          width: 450px;
          height: 450px;
        }
        &--ecart{
          margin: 0 0 80px 0;
        }
      }
  }
}