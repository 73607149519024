/* mise en place des grids */ 

.t1{
    grid-area: t1;
}
.im{
    grid-area: im;
}
.p1{
    grid-area: p1;
}
.l1{
    grid-area: l1;
}

.sect__grid4{
    opacity: 1;
    transform: scale(0.01);
    transition: 0.6s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin: 0 20px;
    padding: 200px 0 0 0 ;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 214px 120px;
    column-gap: 16px;
    row-gap: 62px;
    grid-template-areas: 
    "t1 t1 t1 t1  .  .  .  . "
    "p1 p1 p1 p1 p1  .  .  . ";
  /*  margin: 0 80px;
    padding: 200px 0 0 0 ;
    height: 90vh;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 214px 120px;
    column-gap: 20px;
    row-gap: 124px;
    grid-template-areas: 
    "t1 t1 t1 t1  .  .  .  .  .  . "
    ".  .  p1 p1 p1 p1 p1  .  .  . ";*/
}

@media(min-width:600px){
    .sect__grid4{
        opacity: 1;
        transform: scale(0.01);
        transition: 0.6s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        margin: 0 64px;
        padding: 200px 0 0 0 ;
        height: 90vh;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 214px 120px;
        column-gap: 16px;
        row-gap: 74px;
        grid-template-areas: 
        "t1 t1 t1 t1  .  .  .  .  .  . "
        ".  .  p1 p1 p1 p1 p1  .  .  . ";
      /*  margin: 0 80px;
        padding: 200px 0 0 0 ;
        height: 90vh;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: 214px 120px;
        column-gap: 20px;
        row-gap: 124px;
        grid-template-areas: 
        "t1 t1 t1 t1  .  .  .  .  .  . "
        ".  .  p1 p1 p1 p1 p1  .  .  . ";*/
    }
}
@media(min-width:1024px){
    .sect__grid4{
        opacity: 1;
        transform: scale(0.01);
        transition: 0.6s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        display: block;
        margin: 0 80px;
        padding: 200px 0 0 0 ;
        height: 90vh;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: 214px 120px;
        column-gap: 20px;
        row-gap: 124px;
        grid-template-areas: 
        "t1 t1 t1 t1  .  .  .  .  .  . "
        ".  .  p1 p1 p1 p1 p1  .  .  . ";
      /*  margin: 0 80px;
        padding: 200px 0 0 0 ;
        height: 90vh;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: 214px 120px;
        column-gap: 20px;
        row-gap: 124px;
        grid-template-areas: 
        "t1 t1 t1 t1  .  .  .  .  .  . "
        ".  .  p1 p1 p1 p1 p1  .  .  . ";*/
    }
}