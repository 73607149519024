/* les classes pour le js*/ 

.hidden{
    visibility: hidden;
}
.ghostBox{
    height: 10vh;
  
}
.box1{
    height: 100vh;
}
.box2{
    height: 100vh;
}
.box3{
    height: 100vh;
}
/*.sect__grid4.active{
    transform: scale(1);
    transition: 0.6s;
    color: red;
    position: fixed;
    top:200;
    left: 0;

    z-index: 100;
    margin: 0 80px;
    padding: 200px 0 0 0 ;
    height: 90vh;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 214px 120px;
    column-gap: 20px;
    row-gap: 124px;
    grid-template-areas: 
    "t1 t1 t1 t1  .  .  .  .  .  . "
    ".  .  p1 p1 p1 p1 p1  .  .  . ";
}*/
.sect__grid4.blue{
    transform: scale(1);
    transition: 0.6s;
    color: red;
    position: sticky;
    top:200;
    z-index: 1;
    margin: 0 80px;
    padding: 200px 0 0 0 ;
    height: 90vh;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 214px 120px;
    column-gap: 20px;
    row-gap: 124px;
    grid-template-areas: 
    "t1 t1 t1 t1  .  .  .  .  .  . "
    ".  .  p1 p1 p1 p1 p1  .  .  . ";
}
.para1, .para2, .para3 {
    display: block;
    opacity: 0.01;
    transform: scale(0.01);
    transition: 0.8s;
}

.para1.active, .para2.active,.para3.active {
    transform: scale(1);
    opacity: 1;
    transition: 0.3s;
    color: red;
    position: fixed;
    top:0;
    left: 0;
    z-index: 100;
    margin: 0 20px;
    padding: 200px 0 0 0 ;
    height: 90vh;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 80px 150px;
    column-gap: 16px;
    row-gap: 62px;
    grid-template-areas: 
    "t1 t1 t1 t1  .  .  .  . "
    "p1 p1 p1 p1 p1  .  .  . ";
    & p{
        animation: toScale 0.2s linear ; 
    }
}


@keyframes toScale {
    0%{
        opacity: 0;
    }
    60%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@media(min-width:600px){
    .para1, .para2, .para3 {
        display: block;
        opacity: 0.01;
        transform: scale(0.01);
        transition: 0.8s;
    }
    .para1.active, .para2.active,.para3.active {
        opacity: 1;
        transform: scale(0.01);
        transition: 0.6s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        margin: 0 64px;
        padding: 200px 0 0 0 ;
        height: 90vh;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 214px 120px;
        column-gap: 16px;
        row-gap: 74px;
        grid-template-areas: 
        "t1 t1 t1 t1  .  .  .  .  .  . "
        ".  .  p1 p1 p1 p1 p1  .  .  . ";
      /*  margin: 0 80px;
        padding: 200px 0 0 0 ;
        height: 90vh;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: 214px 120px;
        column-gap: 20px;
        row-gap: 124px;
        grid-template-areas: 
        "t1 t1 t1 t1  .  .  .  .  .  . "
        ".  .  p1 p1 p1 p1 p1  .  .  . ";*/
    }
}
@media(min-width:1024px){
    .para1, .para2, .para3 {
        display: block;
        opacity: 0.01;
        transform: scale(0.01);
        transition: 0.8s;
    }
    .para1.active, .para2.active,.para3.active {
        transform: scale(1);
        opacity: 1;
        transition: 0.3s;
        color: red;
        position: fixed;
        top:0;
        left: 0;
        z-index: 100;
        margin: 0 80px;
        padding: 20vh 0 0 0 ;
        height: 90vh;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: 214px 120px;
        column-gap: 20px;
        row-gap: 124px;
        grid-template-areas: 
        "t1 t1 t1 t1  .  .  .  .  .  . "
        ".  .  p1 p1 p1 p1 p1  .  .  . ";
        & p{
            animation-delay: 1s;
            animation: toScale 0.8s linear ; 
        }
    }
    
}